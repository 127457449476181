<template>
  <el-dialog
    :visible.sync="visible"
    :title="title"
    :close-on-click-modal="false"
    class="f-dialog"
    :before-close="close"
    width="500px"
  >
    <el-form :model="formData" class="form" ref="form" label-width="120px">
      <el-form-item
        v-if="!id"
        label="开盘类型："
        prop="activityType"
        :rules="[$formRules.required()]"
      >
        <el-radio-group v-model="formData.activityType" size="medium">
          <el-radio-button :label="0">选房</el-radio-button>
          <el-radio-button :label="1">选车位</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="活动名称："
        prop="activityName"
        :rules="[$formRules.required()]"
      >
        <el-input
          v-model="formData.activityName"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="开盘模式："
        prop="activityModeType"
        :rules="[$formRules.required()]"
      >
        <el-radio-group v-model="formData.activityModeType" size="medium">
          <el-radio-button :label="1">邀请式</el-radio-button>
          <el-radio-button :label="2">开放式</el-radio-button>
        </el-radio-group>
        <template v-if="formData.activityModeType == 1">
          <p class="tip mt10">邀请模式：需完成预约登记，才能登录在线选房平台</p>
          <div class="open-reservation">
            是否开通线上预约通道
            <el-switch v-model="formData.reservation"></el-switch>
          </div>
          <p class="tip">
            关闭通道后，只能提前将参与在线认购的客户名单录入到“预约登记”，以便于客户登录在线认购平台时，进行身份校验；
          </p>
        </template>
        <p class="tip mt10" v-else>
          开放模式：直接登录选车位平台，认购时再完善相关信息
        </p>
      </el-form-item>
      <el-form-item
        label="诚意金："
        prop="earnestMoney"
        :rules="[$formRules.required(), $formRules.number]"
        v-if="formData.activityModeType == 1"
      >
        <el-input v-model="formData.earnestMoney" placeholder="请输入">
          <template slot="append">元</template>
        </el-input>
        <p class="tip mt8">
          诚意金是参与在线认购的前提条件，选{{
            typeStr
          }}后诚意金将自动转为认购金；不需要缴纳诚意金时，设置为0即可
        </p>
      </el-form-item>
      <el-form-item
        label="认购金："
        prop="subscribeMoney"
        :rules="[$formRules.required(), $formRules.number]"
      >
        <el-input v-model="formData.subscribeMoney" placeholder="请输入">
          <template slot="append">元</template>
        </el-input>
        <p class="tip mt8">
          认购时，需要支付的认购金额；不需要支付认购金时，设置为0即可
        </p>
      </el-form-item>
      <el-form-item
        label="金融方案："
        prop="loan"
        :rules="[$formRules.required()]"
      >
        <el-radio-group v-model="formData.loan" size="medium">
          <el-radio-button :label="true">支持</el-radio-button>
          <el-radio-button :label="false">不支持</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="认购限制："
        prop="numberLimit"
        :rules="[$formRules.required(), $formRules.pInt]"
      >
        <el-input v-model="formData.numberLimit" placeholder="请输入">
          <template slot="append">套/人</template>
        </el-input>
      </el-form-item>
      <el-form-item
        :label="`模拟选${typeStr}：`"
        prop="simulationStartTime"
        :rules="[$formRules.required()]"
      >
        <el-date-picker
          style="width:100%"
          v-model="formData.simulationStartTime"
          type="datetime"
          :placeholder="`模拟选${typeStr}开始时间`"
          format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item
        :label="`正式选${typeStr}：`"
        prop="formalStartTime"
        :rules="[
          $formRules.required(),
          { validator: checkTime2, trigger: 'blur' }
        ]"
      >
        <el-date-picker
          style="width:100%"
          v-model="formData.formalStartTime"
          type="datetime"
          :placeholder="`正式选${typeStr}开始时间`"
          format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item
        :label="`结束选${typeStr}：`"
        prop="formalEndTime"
        :rules="[
          $formRules.required(),
          { validator: checkTime3, trigger: 'blur' }
        ]"
      >
        <el-date-picker
          style="width:100%"
          v-model="formData.formalEndTime"
          type="datetime"
          :placeholder="`结束选${typeStr}开始时间`"
          format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item
        label="咨询电话："
        prop="telephone"
        :rules="[$formRules.required(), $formRules.phoneAll]"
      >
        <el-input v-model="formData.telephone" placeholder="活动咨询电话">
        </el-input>
      </el-form-item>
      <el-form-item
        label="活动海报："
        prop="posterUrl"
        :rules="[$formRules.required()]"
      >
        <div class="auth-content-wrap">
          <p class="color9 lh40">建议尺寸400px *300px，大小1M以内</p>
          <f-upload
            required
            :type="2"
            :default-file-list="formData.posterUrl"
            @on-success="uploadSuccess"
          ></f-upload>
        </div>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="submit">保 存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");
import {
  createOpening,
  editOpening,
  getOpeningInfo
} from "@/api/openQuotationActivity";

import FUpload from "@/components/upload";

const formData = {
  activityName: "",
  activityType: 0,
  activityModeType: 1, // 盘活动模式 1-邀请 2-公开
  loan: true, // 是否可以贷款
  earnestMoney: null, // 诚意金
  subscribeMoney: null, // 认购金
  estateId: null,
  formalEndTime: "",
  formalStartTime: "",
  id: null,
  numberLimit: 1,
  posterUrl: "",
  reservation: true,
  simulationStartTime: "",
  telephone: ""
};
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String, Number],
      default: ""
    }
  },
  components: {
    FUpload
  },
  data() {
    return {
      formData: {},
      func: null
    };
  },
  computed: {
    title() {
      let title = "创建开盘活动";
      if (this.id) {
        title = "编辑开盘活动";
      }
      return title;
    },
    typeStr() {
      let type = this.formData.activityType;
      let typeStr = "";
      switch (type) {
        case 0:
          typeStr = "房";
          break;
        case 1:
          typeStr = "车位";
          break;
      }
      return typeStr;
    }
  },
  watch: {
    visible(e) {
      if (e) {
        this.init();
      }
    }
  },
  methods: {
    checkTime2(rule, value, callback) {
      let time = this.formData.simulationStartTime;
      if (moment(time).isBefore(value)) {
        callback();
      } else {
        callback(new Error("时间须大于模拟时间"));
      }
    },
    checkTime3(rule, value, callback) {
      let time = this.formData.formalStartTime;
      if (moment(time).isBefore(value)) {
        callback();
      } else {
        callback(new Error("时间须大于正式时间"));
      }
    },
    close() {
      this.$emit("update:visible", false);
    },
    init() {
      const { id } = this;
      if (id) {
        this.getOpeningInfo();
        this.func = editOpening;
      } else {
        this.formData = this.$deepClone(formData);
        this.func = createOpening;
      }
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      });
    },
    async getOpeningInfo() {
      const res = await getOpeningInfo(this.id);
      if (res) {
        this.formData = this.$deepClone(res);
        this.$refs.form.clearValidate();
      }
    },
    submit() {
      this.$refs.form.validate(async valid => {
        if (!valid) {
          return;
        }
        const res = await this.func(this.formData);
        if (res) {
          this.$showSuccess("操作成功");
          this.close();
          this.$emit("refresh");
        }
      });
    },
    uploadSuccess(fileList) {
      this.formData.posterUrl = fileList[0].url;
    }
  }
};
</script>
<style lang="scss" scoped>
.tip {
  font-size: 13px;
  color: #999;
}
.open-reservation {
  @include flex-ycb;
  color: #333;
}
::v-deep .el-radio-button--medium .el-radio-button__inner {
  min-width: 90px;
}
</style>
