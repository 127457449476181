var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    staticClass: "f-dialog",
    attrs: {
      "visible": _vm.visible,
      "title": _vm.title,
      "close-on-click-modal": false,
      "before-close": _vm.close,
      "width": "700px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('div', [_c('p', {
    staticClass: "bold fs16 color3 mb16"
  }, [_vm._v(" 《在线选" + _vm._s(_vm.options.activityType == 1 ? "车位" : "房") + "须知》 ")]), _c('quill-editor', {
    ref: "text",
    staticClass: "myQuillEditor",
    attrs: {
      "options": _vm.editorOption
    },
    model: {
      value: _vm.content,
      callback: function callback($$v) {
        _vm.content = $$v;
      },
      expression: "content"
    }
  })], 1), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": _vm.close
    }
  }, [_vm._v("取 消")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("保 存")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }