<template>
  <el-dialog
    :visible.sync="visible"
    :title="title"
    :close-on-click-modal="false"
    class="f-dialog"
    :before-close="close"
    width="700px"
  >
    <div>
      <p class="bold fs16 color3 mb16">
        《在线选{{ options.activityType == 1 ? "车位" : "房" }}须知》
      </p>
      <quill-editor
        ref="text"
        v-model="content"
        class="myQuillEditor"
        :options="editorOption"
      />
    </div>
    <div slot="footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="submit">保 存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { editProtocol } from "@/api/openQuotationActivity";

import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  components: {
    quillEditor
  },
  data() {
    return {
      title: "",
      content: "",
      editorOption: {
        placeholder: "协议正文"
      }
    };
  },
  watch: {
    visible(e) {
      if (e) {
        this.init();
      }
    }
  },
  methods: {
    close() {
      this.$emit("update:visible", false);
    },
    init() {
      const { protocolContent } = this.options;
      this.content = protocolContent;
      this.setTitle(protocolContent);
    },
    setTitle(e) {
      let title = "创建";
      if (e) {
        title = "编辑";
      }
      this.title = `${title}认购协议`;
    },
    async submit() {
      const { id } = this.options;
      const res = await editProtocol(id, {
        protocolContent: this.content
      });
      if (res) {
        this.$showSuccess("操作成功");
        this.close();
        this.$emit("refresh");
      }
    }
  }
};
</script>

<style lang="scss">
.ql-container {
  min-height: 200px;
}
</style>
