import request from "@/utils/request";

// 获取开盘活动列表
export function getOpeningList(estateId) {
  return request({
    url: `/api/v1/platform/openQuotationActivity/${estateId}/list`,
    method: "get"
  });
}

// 获取开盘活动详情
export function getOpeningInfo(id) {
  return request({
    url: `/api/v1/platform/openQuotationActivity/info/${id}`,
    method: "get"
  });
}

// 开盘活动上下架
export function statusSwitch(id, data) {
  return request({
    url: `/api/v1/platform/openQuotationActivity/statusSwitch/${id}`,
    method: "post",
    params: data
  });
}

// 删除开盘活动详情
export function deleteOpening(id) {
  return request({
    url: `/api/v1/platform/openQuotationActivity/delete/${id}`,
    method: "post"
  });
}

// 编辑和创建开盘活动认购书
export function editProtocol(id, data) {
  return request({
    url: `/api/v1/platform/openQuotationActivity/editProtocol/${id}`,
    method: "post",
    data
  });
}

// 创建开盘活动
export function createOpening(data) {
  return request({
    url: `/api/v1/platform/openQuotationActivity/create`,
    method: "post",
    data
  });
}

// 编辑开盘活动
export function editOpening(data) {
  return request({
    url: `/api/v1/platform/openQuotationActivity/edit`,
    method: "post",
    data
  });
}

// 总平图以及坐标信息
export function getPlaneImageInfo(activityId, bindId) {
  return request({
    url: `/api/v1/platform/openQuotationActivity/planeImageInfo/${activityId}/${bindId}`,
    method: "get"
  });
}

// 新增编辑总平图
export function planeImageSaveOrUpdate(data) {
  return request({
    url: `/api/v1/platform/openQuotationActivity/planeImageSaveOrUpdate`,
    method: "post",
    data
  });
}
