var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "opening"
  }, [_c('div', {
    staticClass: "header-mol"
  }, [_vm._m(0), _c('div', [_c('el-button', {
    attrs: {
      "type": "primary",
      "icon": "el-icon-plus"
    },
    on: {
      "click": function click($event) {
        return _vm.showDialog({});
      }
    }
  }, [_vm._v(" 创建开盘 ")])], 1)]), _c('el-table', {
    staticClass: "body",
    attrs: {
      "border": "",
      "data": _vm.list
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "活动信息"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return _c('div', {
          staticClass: "x"
        }, [_c('div', {
          staticClass: "cover mr10"
        }, [scope.row.activityStatus == 0 ? _c('div', {
          staticClass: "img-tag",
          staticStyle: {
            "background-color": "#eb3223"
          }
        }, [_vm._v(" 未开始 ")]) : _vm._e(), scope.row.activityStatus == 1 ? _c('div', {
          staticClass: "img-tag",
          staticStyle: {
            "background-color": "#f29d38"
          }
        }, [_vm._v(" 模拟 ")]) : _vm._e(), scope.row.activityStatus == 2 ? _c('div', {
          staticClass: "img-tag",
          staticStyle: {
            "background-color": "#6cba43"
          }
        }, [_vm._v(" 正式 ")]) : _vm._e(), scope.row.activityStatus == 3 ? _c('div', {
          staticClass: "img-mask x1 aic"
        }, [_vm._v(" 已结束 ")]) : _vm._e(), _c('el-image', {
          staticClass: "cover",
          attrs: {
            "fit": "cover",
            "src": scope.row.posterUrl
          }
        })], 1), _c('div', {
          staticClass: "height y3",
          class: {
            opacity: scope.row.activityStatus == 3
          }
        }, [_c('p', {
          staticClass: "fs16 bold lh28"
        }, [_vm._v(_vm._s(scope.row.activityName))]), _c('div', {
          staticClass: "x"
        }, [_c('div', {
          staticClass: "mr10"
        }, [_c('p', {
          staticClass: "lh28"
        }, [_c('span', {
          staticClass: "color9"
        }, [_vm._v("类型:")]), _c('span', [_vm._v(_vm._s(scope.row.activityType == 1 ? "车位" : "选房"))])]), _c('p', {
          staticClass: "lh28"
        }, [_c('span', {
          staticClass: "color9"
        }, [_vm._v("限制:")]), _c('span', [_vm._v(_vm._s(scope.row.numberLimit) + "套/人")])])]), _c('div', [_c('p', {
          staticClass: "lh28"
        }, [_c('span', {
          staticClass: "color9"
        }, [_vm._v("预约:")]), scope.row.reservation ? _c('span', [_vm._v("线上+线下")]) : _c('span', [_vm._v("线下")])]), _c('p', {
          staticClass: "lh28"
        }, [_c('span', {
          staticClass: "color9"
        }, [_vm._v("创建人:")]), _c('span', [_vm._v(_vm._s(scope.row.createUserName))])])])])])]);
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "活动节点"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return scope.row.activityType == 1 ? _c('div', {
          class: {
            opacity: scope.row.activityStatus == 3
          }
        }, [_c('p', {
          staticClass: "lh28"
        }, [_vm._v("模拟选车位：" + _vm._s(scope.row.simulationStartTime))]), _c('p', {
          staticClass: "lh28"
        }, [_vm._v("正式选车位：" + _vm._s(scope.row.formalStartTime))]), _c('p', {
          staticClass: "lh28"
        }, [_vm._v("选车位结束：" + _vm._s(scope.row.formalEndTime))])]) : _c('div', {
          class: {
            opacity: scope.row.activityStatus == 3
          }
        }, [_c('p', {
          staticClass: "lh28"
        }, [_vm._v("模拟选房：" + _vm._s(scope.row.simulationStartTime))]), _c('p', {
          staticClass: "lh28"
        }, [_vm._v("正式选房：" + _vm._s(scope.row.formalStartTime))]), _c('p', {
          staticClass: "lh28"
        }, [_vm._v("选房结束：" + _vm._s(scope.row.formalEndTime))])]);
      }
    }], null, true)
  }), _c('el-table-column', {
    attrs: {
      "label": "认购金",
      "width": "180px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return _c('div', {
          class: {
            opacity: scope.row.activityStatus == 3
          }
        }, [_c('p', {
          staticClass: "red"
        }, [_vm._v("¥" + _vm._s(_vm._f("toThousands")(scope.row.subscribeMoney)))]), scope.row.earnestMoney ? _c('p', [_vm._v(" 诚意金¥" + _vm._s(_vm._f("toThousands")(scope.row.earnestMoney)) + " ")]) : _vm._e()]);
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "金融方案",
      "width": "180px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return _c('div', {}, [_c('p', {
          class: {
            opacity: scope.row.activityStatus == 3
          }
        }, [_vm._v(" " + _vm._s(scope.row.loan ? "支持" : "不支持") + " ")])]);
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "认购协议",
      "width": "180px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return _c('div', {}, [!scope.row.protocol ? _c('el-button', {
          staticClass: "red",
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.showQuillEditorDialog(scope.row);
            }
          }
        }, [_vm._v(" 创建协议 ")]) : _c('el-button', {
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.showQuillEditorDialog(scope.row);
            }
          }
        }, [_vm._v(" 编辑协议 ")])], 1);
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "状态",
      "width": "100px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return _c('div', {
          class: {
            opacity: scope.row.activityStatus == 3
          }
        }, [scope.row.status == 1 ? _c('p', {
          staticClass: "color3"
        }, [_vm._v("已发布")]) : _c('p', {
          staticClass: "red"
        }, [_vm._v("草稿")])]);
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "操作",
      "width": "180px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.status == 0 ? _c('el-button', {
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.statusSwitch(scope.row);
            }
          }
        }, [_vm._v(" 发布 ")]) : _vm._e(), scope.row.status == 1 ? _c('el-button', {
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.statusSwitch(scope.row);
            }
          }
        }, [_vm._v(" 下架 ")]) : _vm._e(), _c('el-button', {
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.showDialog(scope.row);
            }
          }
        }, [_vm._v(" 编辑 ")]), scope.row.status == 0 ? _c('el-button', {
          staticClass: "red",
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.deleteOpening(scope.row.id);
            }
          }
        }, [_vm._v(" 删除 ")]) : _vm._e()];
      }
    }])
  })], 1), _c('editForm', {
    attrs: {
      "visible": _vm.toggleDialog,
      "id": _vm.currentItem.id
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.toggleDialog = $event;
      },
      "refresh": _vm.getData
    }
  }), _c('quillEditor', {
    attrs: {
      "visible": _vm.toggleQuillEditorDialog,
      "options": _vm.currentItem
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.toggleQuillEditorDialog = $event;
      },
      "refresh": _vm.getData
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info"
  }, [_c('h3', {
    staticClass: "title"
  }, [_vm._v("开盘活动")]), _c('p', {
    staticClass: "summary"
  }, [_vm._v(" 网上开盘前，需要先创建开盘活动，目前支持“在线选房”和“在线选车位”两类开盘活动； ")])]);

}]

export { render, staticRenderFns }