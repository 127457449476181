<template>
  <div class="opening">
    <div class="header-mol">
      <div class="info">
        <h3 class="title">开盘活动</h3>
        <p class="summary">
          网上开盘前，需要先创建开盘活动，目前支持“在线选房”和“在线选车位”两类开盘活动；
        </p>
      </div>
      <div>
        <el-button type="primary" icon="el-icon-plus" @click="showDialog({})">
          创建开盘
        </el-button>
      </div>
    </div>
    <el-table border class="body" :data="list">
      <el-table-column label="活动信息">
        <div slot-scope="scope" class="x">
          <div class="cover mr10">
            <div
              class="img-tag"
              v-if="scope.row.activityStatus == 0"
              style="background-color: #eb3223"
            >
              未开始
            </div>
            <div
              class="img-tag"
              v-if="scope.row.activityStatus == 1"
              style="background-color: #f29d38"
            >
              模拟
            </div>
            <div
              class="img-tag"
              v-if="scope.row.activityStatus == 2"
              style="background-color: #6cba43"
            >
              正式
            </div>
            <div class="img-mask x1 aic" v-if="scope.row.activityStatus == 3">
              已结束
            </div>
            <el-image
              class="cover"
              fit="cover"
              :src="scope.row.posterUrl"
            ></el-image>
          </div>
          <div
            class="height y3"
            :class="{ opacity: scope.row.activityStatus == 3 }"
          >
            <p class="fs16 bold lh28">{{ scope.row.activityName }}</p>
            <div class="x">
              <div class="mr10">
                <p class="lh28">
                  <span class="color9">类型:</span>
                  <span>{{
                    scope.row.activityType == 1 ? "车位" : "选房"
                  }}</span>
                </p>
                <p class="lh28">
                  <span class="color9">限制:</span>
                  <span>{{ scope.row.numberLimit }}套/人</span>
                </p>
              </div>
              <div>
                <p class="lh28">
                  <span class="color9">预约:</span>
                  <span v-if="scope.row.reservation">线上+线下</span>
                  <span v-else>线下</span>
                </p>
                <p class="lh28">
                  <span class="color9">创建人:</span>
                  <span>{{ scope.row.createUserName }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </el-table-column>
      <el-table-column label="活动节点">
        <div
          slot-scope="scope"
          v-if="scope.row.activityType == 1"
          :class="{ opacity: scope.row.activityStatus == 3 }"
        >
          <p class="lh28">模拟选车位：{{ scope.row.simulationStartTime }}</p>
          <p class="lh28">正式选车位：{{ scope.row.formalStartTime }}</p>
          <p class="lh28">选车位结束：{{ scope.row.formalEndTime }}</p>
        </div>
        <div
          slot-scope="scope"
          v-else
          :class="{ opacity: scope.row.activityStatus == 3 }"
        >
          <p class="lh28">模拟选房：{{ scope.row.simulationStartTime }}</p>
          <p class="lh28">正式选房：{{ scope.row.formalStartTime }}</p>
          <p class="lh28">选房结束：{{ scope.row.formalEndTime }}</p>
        </div>
      </el-table-column>
      <el-table-column label="认购金" width="180px">
        <div
          slot-scope="scope"
          :class="{ opacity: scope.row.activityStatus == 3 }"
        >
          <p class="red">¥{{ scope.row.subscribeMoney | toThousands }}</p>
          <p v-if="scope.row.earnestMoney">
            诚意金¥{{ scope.row.earnestMoney | toThousands }}
          </p>
        </div>
      </el-table-column>
      <el-table-column label="金融方案" width="180px">
        <div slot-scope="scope">
          <p :class="{ opacity: scope.row.activityStatus == 3 }">
            {{ scope.row.loan ? "支持" : "不支持" }}
          </p>
        </div>
      </el-table-column>
      <el-table-column label="认购协议" width="180px">
        <div slot-scope="scope">
          <el-button
            class="red"
            v-if="!scope.row.protocol"
            type="text"
            @click="showQuillEditorDialog(scope.row)"
          >
            创建协议
          </el-button>
          <el-button
            v-else
            type="text"
            @click="showQuillEditorDialog(scope.row)"
          >
            编辑协议
          </el-button>
        </div>
      </el-table-column>
      <el-table-column label="状态" width="100px">
        <div
          slot-scope="scope"
          :class="{ opacity: scope.row.activityStatus == 3 }"
        >
          <p v-if="scope.row.status == 1" class="color3">已发布</p>
          <p v-else class="red">草稿</p>
        </div>
      </el-table-column>
      <el-table-column label="操作" width="180px">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.status == 0"
            type="text"
            @click="statusSwitch(scope.row)"
          >
            发布
          </el-button>
          <el-button
            v-if="scope.row.status == 1"
            type="text"
            @click="statusSwitch(scope.row)"
          >
            下架
          </el-button>
          <el-button type="text" @click="showDialog(scope.row)">
            编辑
          </el-button>
          <el-button
            v-if="scope.row.status == 0"
            type="text"
            class="red"
            @click="deleteOpening(scope.row.id)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <editForm
      :visible.sync="toggleDialog"
      :id="currentItem.id"
      @refresh="getData"
    />
    <quillEditor
      :visible.sync="toggleQuillEditorDialog"
      :options="currentItem"
      @refresh="getData"
    />
  </div>
</template>

<script>
import {
  getOpeningList,
  statusSwitch,
  deleteOpening
} from "@/api/openQuotationActivity";
import editForm from "./components/edit";
import quillEditor from "./components/quillEditor";
export default {
  components: {
    editForm,
    quillEditor
  },
  data() {
    return {
      list: [],
      toggleDialog: false,
      toggleQuillEditorDialog: false,
      currentItem: {}
    };
  },
  computed: {
    estateId() {
      return this.$store.state.estateInfo.estateId;
    }
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      let estateId = this.estateId || null;
      if (!estateId) {
        return;
      }
      const res = await getOpeningList(estateId);
      if (res) {
        this.list = res;
      }
    },
    showDialog(row) {
      this.toggleDialog = true;
      this.currentItem = this.$deepClone(row);
    },
    showQuillEditorDialog(row) {
      this.toggleQuillEditorDialog = true;
      this.currentItem = this.$deepClone(row);
    },
    async statusSwitch(row) {
      let { id, status } = row;
      status = status == 0 ? 1 : 0;
      const res = await statusSwitch(id, { status });
      if (res) {
        this.$showSuccess("操作成功");
        this.getData();
      }
    },
    async deleteOpening(id) {
      //删除榜单规则
      this.$confirm("确定删除该条数据吗？", "删除数据", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          const res = await deleteOpening(id);
          if (res) {
            this.$showSuccess("删除成功");
            this.getData();
          }
        })
        .catch(() => {});
    }
  }
};
</script>
<style scoped lang="scss">
.opening {
  background: #fff;
  padding: 30px;
  min-height: 100%;
  box-shadow: 0 0 5px #ddd;
  border-radius: 5px;
  .head {
    .title {
      h3 {
        font-size: 16px;
        line-break: 16px;
        padding-left: 20px;
        border-left: 3px solid $primary;
      }
      p {
        margin-top: 10px;
        padding-left: 20px;
        color: $color9;
      }
    }
  }
  .body {
    margin-top: 30px;
    ::v-deeptd .cell {
      height: 90px;
    }
    ::v-deep {
      .el-button--text {
        padding: 0;
      }
    }
    .cover {
      min-width: 120px;
      width: 120px;
      height: 90px;
      border-radius: 1px;
      overflow: hidden;
      position: relative;
      .img-tag {
        position: absolute;
        left: 0;
        top: 0;
        width: 50px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        font-size: 13px;
        color: #fff;
        z-index: 1;
      }
      .img-mask {
        position: absolute;
        left: 0;
        top: 0;
        width: 120px;
        height: 90px;
        border-radius: 1px;
        z-index: 1;
        background: rgba($color: #000000, $alpha: 0.3);
        font-size: 13px;
        color: #fff;
      }
    }
  }
}
</style>
