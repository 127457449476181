var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    staticClass: "f-dialog",
    attrs: {
      "visible": _vm.visible,
      "title": _vm.title,
      "close-on-click-modal": false,
      "before-close": _vm.close,
      "width": "500px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('el-form', {
    ref: "form",
    staticClass: "form",
    attrs: {
      "model": _vm.formData,
      "label-width": "120px"
    }
  }, [!_vm.id ? _c('el-form-item', {
    attrs: {
      "label": "开盘类型：",
      "prop": "activityType",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-radio-group', {
    attrs: {
      "size": "medium"
    },
    model: {
      value: _vm.formData.activityType,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "activityType", $$v);
      },
      expression: "formData.activityType"
    }
  }, [_c('el-radio-button', {
    attrs: {
      "label": 0
    }
  }, [_vm._v("选房")]), _c('el-radio-button', {
    attrs: {
      "label": 1
    }
  }, [_vm._v("选车位")])], 1)], 1) : _vm._e(), _c('el-form-item', {
    attrs: {
      "label": "活动名称：",
      "prop": "activityName",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.activityName,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "activityName", $$v);
      },
      expression: "formData.activityName"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "开盘模式：",
      "prop": "activityModeType",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-radio-group', {
    attrs: {
      "size": "medium"
    },
    model: {
      value: _vm.formData.activityModeType,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "activityModeType", $$v);
      },
      expression: "formData.activityModeType"
    }
  }, [_c('el-radio-button', {
    attrs: {
      "label": 1
    }
  }, [_vm._v("邀请式")]), _c('el-radio-button', {
    attrs: {
      "label": 2
    }
  }, [_vm._v("开放式")])], 1), _vm.formData.activityModeType == 1 ? [_c('p', {
    staticClass: "tip mt10"
  }, [_vm._v("邀请模式：需完成预约登记，才能登录在线选房平台")]), _c('div', {
    staticClass: "open-reservation"
  }, [_vm._v(" 是否开通线上预约通道 "), _c('el-switch', {
    model: {
      value: _vm.formData.reservation,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "reservation", $$v);
      },
      expression: "formData.reservation"
    }
  })], 1), _c('p', {
    staticClass: "tip"
  }, [_vm._v(" 关闭通道后，只能提前将参与在线认购的客户名单录入到“预约登记”，以便于客户登录在线认购平台时，进行身份校验； ")])] : _c('p', {
    staticClass: "tip mt10"
  }, [_vm._v(" 开放模式：直接登录选车位平台，认购时再完善相关信息 ")])], 2), _vm.formData.activityModeType == 1 ? _c('el-form-item', {
    attrs: {
      "label": "诚意金：",
      "prop": "earnestMoney",
      "rules": [_vm.$formRules.required(), _vm.$formRules.number]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.earnestMoney,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "earnestMoney", $$v);
      },
      expression: "formData.earnestMoney"
    }
  }, [_c('template', {
    slot: "append"
  }, [_vm._v("元")])], 2), _c('p', {
    staticClass: "tip mt8"
  }, [_vm._v(" 诚意金是参与在线认购的前提条件，选" + _vm._s(_vm.typeStr) + "后诚意金将自动转为认购金；不需要缴纳诚意金时，设置为0即可 ")])], 1) : _vm._e(), _c('el-form-item', {
    attrs: {
      "label": "认购金：",
      "prop": "subscribeMoney",
      "rules": [_vm.$formRules.required(), _vm.$formRules.number]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.subscribeMoney,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "subscribeMoney", $$v);
      },
      expression: "formData.subscribeMoney"
    }
  }, [_c('template', {
    slot: "append"
  }, [_vm._v("元")])], 2), _c('p', {
    staticClass: "tip mt8"
  }, [_vm._v(" 认购时，需要支付的认购金额；不需要支付认购金时，设置为0即可 ")])], 1), _c('el-form-item', {
    attrs: {
      "label": "金融方案：",
      "prop": "loan",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-radio-group', {
    attrs: {
      "size": "medium"
    },
    model: {
      value: _vm.formData.loan,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "loan", $$v);
      },
      expression: "formData.loan"
    }
  }, [_c('el-radio-button', {
    attrs: {
      "label": true
    }
  }, [_vm._v("支持")]), _c('el-radio-button', {
    attrs: {
      "label": false
    }
  }, [_vm._v("不支持")])], 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "认购限制：",
      "prop": "numberLimit",
      "rules": [_vm.$formRules.required(), _vm.$formRules.pInt]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.numberLimit,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "numberLimit", $$v);
      },
      expression: "formData.numberLimit"
    }
  }, [_c('template', {
    slot: "append"
  }, [_vm._v("套/人")])], 2)], 1), _c('el-form-item', {
    attrs: {
      "label": "\u6A21\u62DF\u9009".concat(_vm.typeStr, "\uFF1A"),
      "prop": "simulationStartTime",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-date-picker', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "type": "datetime",
      "placeholder": "\u6A21\u62DF\u9009".concat(_vm.typeStr, "\u5F00\u59CB\u65F6\u95F4"),
      "format": "yyyy-MM-dd HH:mm",
      "value-format": "yyyy-MM-dd HH:mm"
    },
    model: {
      value: _vm.formData.simulationStartTime,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "simulationStartTime", $$v);
      },
      expression: "formData.simulationStartTime"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "\u6B63\u5F0F\u9009".concat(_vm.typeStr, "\uFF1A"),
      "prop": "formalStartTime",
      "rules": [_vm.$formRules.required(), {
        validator: _vm.checkTime2,
        trigger: 'blur'
      }]
    }
  }, [_c('el-date-picker', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "type": "datetime",
      "placeholder": "\u6B63\u5F0F\u9009".concat(_vm.typeStr, "\u5F00\u59CB\u65F6\u95F4"),
      "format": "yyyy-MM-dd HH:mm",
      "value-format": "yyyy-MM-dd HH:mm"
    },
    model: {
      value: _vm.formData.formalStartTime,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "formalStartTime", $$v);
      },
      expression: "formData.formalStartTime"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "\u7ED3\u675F\u9009".concat(_vm.typeStr, "\uFF1A"),
      "prop": "formalEndTime",
      "rules": [_vm.$formRules.required(), {
        validator: _vm.checkTime3,
        trigger: 'blur'
      }]
    }
  }, [_c('el-date-picker', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "type": "datetime",
      "placeholder": "\u7ED3\u675F\u9009".concat(_vm.typeStr, "\u5F00\u59CB\u65F6\u95F4"),
      "format": "yyyy-MM-dd HH:mm",
      "value-format": "yyyy-MM-dd HH:mm"
    },
    model: {
      value: _vm.formData.formalEndTime,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "formalEndTime", $$v);
      },
      expression: "formData.formalEndTime"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "咨询电话：",
      "prop": "telephone",
      "rules": [_vm.$formRules.required(), _vm.$formRules.phoneAll]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "活动咨询电话"
    },
    model: {
      value: _vm.formData.telephone,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "telephone", $$v);
      },
      expression: "formData.telephone"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "活动海报：",
      "prop": "posterUrl",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('div', {
    staticClass: "auth-content-wrap"
  }, [_c('p', {
    staticClass: "color9 lh40"
  }, [_vm._v("建议尺寸400px *300px，大小1M以内")]), _c('f-upload', {
    attrs: {
      "required": "",
      "type": 2,
      "default-file-list": _vm.formData.posterUrl
    },
    on: {
      "on-success": _vm.uploadSuccess
    }
  })], 1)])], 1), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": _vm.close
    }
  }, [_vm._v("取 消")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("保 存")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }